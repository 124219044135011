<template>
    <div class="lead-password-recovery">
      <v-container class="pa-0 mt-sm-4 mt-md-8">
        <div class="card">
          <v-card
            class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
            elevation="0"
            flat
            tile
          >
            <v-form ref="form" lazy-validation>
              <v-row>
                <!-- Password Recovery -->
                <v-col cols="12">
                  <h1 class="header-h1 primary--text text-decoration-none">Thanks for Choosing Lime Loans</h1>
                  <h2 class="header-h1">Create Your Password</h2>
  
                  <p class="b-body-1 mt-3">
                    Enter your ID number and phone number, we will send you an
                    SMS-code
                  </p>
                </v-col>
  
                <!-- ID Number -->
                <v-col cols="12">
                  <v-card class="mt-n4" max-width="170" flat>
                    <p class="label-text mb-1">ID number</p>
  
                    <v-text-field
                      v-if="!otpSend"
                      v-model="idnumber"
                      :rules="idnumberRules"
                      :disabled="otpSend"
                      class="inputPrice"
                      type="number"
                      pattern="/^-?\d+\.?\d*$/"
                      onKeyPress="if(this.value.length==13) return false;"
                      onkeydown="return event.keyCode !== 69"
                      height="48px"
                      full-width
                      outlined
                      dense
                      required
                    ></v-text-field>
  
                    <!-- otpSend -->
                    <v-card
                      v-else
                      class="d-flex align-center justify-center mb-4"
                      color="gray300"
                      width="170"
                      height="48"
                      flat
                    >
                      <div class="input-text gray700--text">
                        {{ idnumber }}
                      </div>
                    </v-card>
                  </v-card>
                </v-col>
  
                <!-- Mobile Phone -->
                <v-col cols="12">
                  <v-card class="mt-n4" max-width="170" flat>
                    <p class="label-text mb-1">Mobile Phone</p>
  
                    <!-- otpSend -->
                    <v-text-field
                      v-if="!otpSend"
                      v-model="mobilePhone"
                      :rules="mobilePhoneRules"
                      :disabled="otpSend"
                      maxlength="12"
                      height="48px"
                      full-width
                      outlined
                      dense
                      required
                    ></v-text-field>
  
                    <!-- otpSend -->
                    <v-card
                      v-else
                      class="d-flex align-center justify-center"
                      color="gray300"
                      width="170"
                      height="48"
                      flat
                    >
                      <div class="input-text gray700--text">
                        {{ mobilePhone }}
                      </div>
                    </v-card>
                  </v-card>
  
                  <v-card v-if="error" color="transparent" width="612" flat>
                    <p class="b-body-1 gray700--text">
                      Your phone number and/or ID are not registered. Please
                      confirm information entered is correct. If you're not yet
                      our client, please
                      <router-link
                        class="b-body-1 primary--text text-decoration-none"
                        :to="{ name: 'RegistrationPage' }"
                      >
                        Register</router-link
                      >.
                    </p>
                  </v-card>
  
                  <!-- Invalid credentials -->
                  <v-card
                    v-if="idnumberHasError || mobilePhoneHasError"
                    class="mt-n4"
                    width="362"
                    flat
                  >
                    <p
                      v-if="accountLocked"
                      class="b-body-1 error500--text mt-4 mb-5"
                    >
                      Your account has been locked out. Please contact a Customer
                      Care Agent on 010 442 6722
                    </p>
  
                    <p v-else class="b-body-1 error500--text mt-4 mb-5">
                      Invalid credentials
                    </p>
                  </v-card>
                </v-col>
  
                <!-- Send SMS-code -->
                <v-col cols="12" class="mt-n5">
                  <v-card v-if="!otpSend" width="362" flat>
                    <!-- Desktop -->
                    <v-btn
                      @click="validate()"
                      class="b-button primary text-capitalize hidden-xs-only"
                      height="48"
                      width="192"
                      elevation="0"
                      :disabled="!readyToSubmit"
                    >
                      Send SMS-code
                    </v-btn>
  
                    <!-- Mobile -->
                    <v-btn
                      @click="validate()"
                      class="b-button primary text-capitalize hidden-sm-and-up"
                      height="48"
                      width="100%"
                      elevation="0"
                      :disabled="!readyToSubmit"
                    >
                      Send SMS-code
                    </v-btn>
                  </v-card>
  
                  <v-form v-if="otpSend" ref="form2" lazy-validation>
                    <!-- The code was sent -->
                    <v-card
                      class="mt-8 mb-n4"
                      color="transparent"
                      width="400"
                      flat
                    >
                      <p class="b-body-1 gray800--text">
                        The code was sent to the number
                        <span class="font-weight-bold gray900--text">{{
                          mobilePhone
                        }}</span>
                      </p>
  
                      <!-- SMS-code -->
                      <p class="label-text mb-1">SMS-code</p>
  
                      <div class="d-flex flex-column flex-sm-row align-sm-center">
                        <v-card width="170" flat>
                          <!-- SMS-code -->
                          <v-text-field
                            class="py-0 my-0 inputPrice"
                            v-model="otp"
                            :rules="otpRules"
                            type="number"
                            onKeyPress="if(this.value.length==5) return false;"
                            height="48px"
                            full-width
                            outlined
                            required
                            dense
                          >
                          </v-text-field>
                        </v-card>
  
                        <!-- Get a new code -->
                        <!-- allowOtpReSend -->
                        <p
                          v-if="!allowOtpReSend"
                          class="b-body-1 ml-sm-5 mt-sm-n1"
                        >
                          <span class="font-weight-bold gray600--text"
                            >Get a new code</span
                          >
                          <countdown :time="time">
                            <template slot-scope="props">
                              {{ props.minutes }}:{{ props.seconds }}
                            </template>
                          </countdown>
                        </p>
  
                        <!-- allowOtpReSend -->
                        <p
                          v-else
                          @click="resetPasswordOtp()"
                          class="b-body-1 primary--text font-weight-bold ml-sm-5 mt-sm-n1 pointer"
                        >
                          Get a new code
                        </p>
                      </div>
                    </v-card>
  
                    <!-- Password -->
                    <div>
                      <!-- Password -->
                      <v-card class="mt-4" max-width="362" flat>
                        <p class="label-text mb-1">New password</p>
  
                        <v-text-field
                          @click:append="showPassword = !showPassword"
                          v-model="password"
                          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="showPassword ? 'text' : 'password'"
                          :rules="passwordRules"
                          height="48px"
                          full-width
                          outlined
                          dense
                          required
                        ></v-text-field>
                      </v-card>
  
                      <!-- Password Rules -->
                      <v-card class="mt-n4" width="362" flat>
                        <!-- Password Rule 1 -->
                        <p
                          class="b-body-2 success--text"
                          v-if="passwordRule1Correct"
                        >
                          <v-icon size="22" class="ma-n0" color="success">
                            mdi-check
                          </v-icon>
  
                          <span class="ml-1">8 to 30 characters</span>
                        </p>
                        <p class="b-body-2" v-else>
                          <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                          <span class="ml-1">8 to 30 characters</span>
                        </p>
  
                        <!-- Password Rule 2 -->
                        <p
                          class="b-body-2 mt-n3 success--text"
                          v-if="passwordRule2Correct"
                        >
                          <v-icon size="22" class="ma-n0" color="success">
                            mdi-check
                          </v-icon>
  
                          <span class="ml-1"
                            >Сontains lowercase and uppercase English letters
                          </span>
                        </p>
                        <p class="b-body-2 mt-n3" v-else>
                          <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                          <span class="ml-1"
                            >Сontains lowercase and uppercase English
                            letters</span
                          >
                        </p>
  
                        <!-- Password Rule 3 -->
                        <p
                          class="b-body-2 mt-n3 success--text"
                          v-if="passwordRule3Correct"
                        >
                          <v-icon size="22" class="ma-n0" color="success">
                            mdi-check
                          </v-icon>
  
                          <span class="ml-1">Сontains letters and numbers</span>
                        </p>
                        <p class="b-body-2 mt-n3" v-else>
                          <v-icon large class="ma-n3">mdi-circle-small</v-icon>
                          <span class="ml-1">Сontains letters and numbers</span>
                        </p>
                      </v-card>
                    </div>
  
                    <!-- btn -->
                    <div>
                      <!-- Desktop -->
                      <div class="hidden-xs-only">
                        <!-- Save -->
                        <v-btn
                          @click="validateForm2()"
                          class="b-btn-text white--text text-capitalize mt-4"
                          width="169"
                          height="48"
                          color="primary"
                          :disabled="!readyToSubmit2"
                        >
                          Save
                        </v-btn>
                      </div>
  
                      <!-- Mobile -->
                      <div class="hidden-sm-and-up">
                        <v-card width="362" flat>
                          <!-- Save -->
                          <v-btn
                            @click="validateForm2()"
                            class="b-btn-text white--text text-capitalize mt-4"
                            width="100%"
                            height="48"
                            color="primary"
                            :disabled="!readyToSubmit2"
                          >
                            Save
                          </v-btn>
                        </v-card>
                      </div>
                    </div>
                  </v-form>
  
                  <!-- I have a password -->
                  <div class="mt-5">
                    <router-link
                      class="b-body-1 primary--text text-decoration-none"
                      :to="{ name: 'LoginPage' }"
                    >
                      I have a password
                    </router-link>
                  </div>
                </v-col>
  
                <!-- Overlay -->
                <v-overlay :value="overlay" z-index="10">
                  <v-progress-circular
                    indeterminate
                    size="64"
                  ></v-progress-circular>
                </v-overlay>
              </v-row>
            </v-form>
          </v-card>
        </div>
      </v-container>
    </div>
  </template>
  
  <script>
  import { mapState, mapMutations } from "vuex";
  
  export default {
    name: "LimeLoansPasswordRecovery",
  
    data() {
      return {
        overlay: false,
        readyToSubmit: false,
        readyToSubmit2: false,
  
        // ID Number
        idnumber: "",
        idnumberRules: [
          (v) => !!v || "",
          (v) => /[0-9]{13}/.test(v) || "ID number is incorrect",
          (v) => v.length == 13 || "ID number is incorrect",
          (v) => (!!v && !this.idnumberHasError) || this.idnumberErrorMessage,
        ],
        idnumberHasError: false,
        idnumberErrorMessage: "ID number is incorrect",
  
        // Mobile Phone
        mobilePhone: "",
        mobilePhoneRules: [
          (v) => !!v || "",
          (v) => /^[0-9\s]+$/.test(v) || "Only 0 to 9 is allowed",
          (v) => v.length == 12 || "Mobile phone is incorrect",
          (v) =>
            (!!v && !this.mobilePhoneHasError) || this.mobilePhoneErrorMessage,
        ],
        mobilePhoneHasError: false,
        mobilePhoneErrorMessage: "Mobile phone is incorrect",
  
        error: false,
        otpSend: false,
        allowOtpReSend: false,
  
        // otp
        otp: "",
        otpRules: [
          (v) => !!v || "",
          (v) => v.length == 5 || "SMS-code is incorrect ",
          (v) => (!!v && !this.otpHasError) || this.otpErrorMessage,
        ],
        otpHasError: false,
        otpErrorMessage: "",
  
        countDown: 0,
        time: 0,
  
        // Password
        password: "",
        passwordRules: [
          (v) => !!v || "",
          (v) => (v && !this.passwordHasError) || "",
          (v) => v.length >= 8 || "",
          (v) => v.length <= 30 || "",
          (v) => /^(?=.*?[A-Z])/.test(v) || "",
          (v) => /^(?=.*?[a-z])/.test(v) || "",
          (v) => /^(?=.*?[0-9])/.test(v) || "",
        ],
        passwordHasError: false,
        passwordErrorMessage: "",
        passwordRule1Correct: false,
        passwordRule2Correct: false,
        passwordRule3Correct: false,
  
        // Show Password
        showPassword: false,
  
        // accountLocked
        accountLocked: false,
      };
    },
  
    computed: {
      ...mapState({ passwordRecovery: "passwordRecovery" }),
    },
  
    created() {
      this.$helpers.resetErrorHandlerState();
    },
  
    watch: {
      // idnumber
      idnumber() {
        if (this.idnumberHasError) {
          this.idnumberHasError = false;
          this.idnumberErrorMessage = "";
  
          this.mobilePhoneHasError = false;
          this.mobilePhoneErrorMessage = "";
        }
  
        this.setReadyToSubmit();
      },
  
      // mobilePhone
      mobilePhone(val) {
        if (this.mobilePhoneHasError) {
          this.idnumberHasError = false;
          this.idnumberErrorMessage = "";
  
          this.mobilePhoneHasError = false;
          this.mobilePhoneErrorMessage = "";
        }
  
        this.mobilePhone = this.$helpers.formatInputMobileNumber(val);
  
        this.setReadyToSubmit();
      },
  
      // otp
      otp() {
        if (this.otpHasError) {
          this.otpHasError = false;
          this.otpErrorMessage = "";
        }
  
        this.setReadyToSubmit2();
        if (this.otp.length == 5) {
          // this.resetPassword();
        }
      },
  
      // password
      password(val) {
        this.validatePassword(val);
        this.setReadyToSubmit2();
      },
    },
  
    mounted() {},
  
    methods: {
      ...mapMutations({
        setJustReseted: "passwordRecovery/setJustReseted",
      }),
  
      // validate
      validate() {
        this.$refs.form.validate();
  
        if (this.$refs.form.validate()) {
          this.resetPasswordOtp();
        }
      },
  
      // validateForm2
      validateForm2() {
        this.$refs.form2.validate();
  
        if (this.$refs.form2.validate()) {
          this.resetPassword();
        }
      },
  
      // validatePassword
      validatePassword(password) {
        // 8 to 30 characters
        if (8 <= password.length && password.length <= 30) {
          this.passwordRule1Correct = true;
        } else {
          this.passwordRule1Correct = false;
        }
  
        // Сontains lowercase and uppercase English letters
        if (/[A-Z]/.test(password) && /[a-z]/.test(password)) {
          this.passwordRule2Correct = true;
        } else {
          this.passwordRule2Correct = false;
        }
  
        // Сontains letters and numbers
        if (/[A-Za-z]/.test(password) && /[0-9]/.test(password)) {
          this.passwordRule3Correct = true;
        } else {
          this.passwordRule3Correct = false;
        }
      },
  
      // setReadyToSubmit
      setReadyToSubmit() {
        if (
          this.idnumber === "" ||
          this.idnumber === null ||
          this.mobilePhone === "" ||
          this.mobilePhone === null ||
          !this.$refs.form.validate()
        ) {
          this.readyToSubmit = false;
        } else {
          this.readyToSubmit = true;
        }
      },
  
      // setReadyToSubmit2
      setReadyToSubmit2() {
        if (
          this.otp === "" ||
          this.otp === null ||
          this.password === "" ||
          this.password === null ||
          !this.$refs.form2.validate()
        ) {
          this.readyToSubmit2 = false;
        } else {
          this.readyToSubmit2 = true;
        }
      },
  
      // countDownTimer
      countDownTimer() {
        if (this.countDown > 0) {
          setTimeout(() => {
            this.countDown -= 1;
            this.countDownTimer();
          }, 1000);
        } else {
          this.allowOtpReSend = true;
        }
      },
  
      // resetPasswordOtp
      async resetPasswordOtp() {
        this.overlay = true;
  
        try {
          let res = await this.axios.post("reset-password-otp", {
            idnumber: this.idnumber,
            cellular: this.mobilePhone.replaceAll(" ", ""),
          });
  
          if (res.status == 200) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "OTP Has Been Send To Your Mobile Number",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
  
            if ("dev_otp" in res.data) {
              this.$swal({
                toast: true,
                position: "top-end",
                icon: "success",
                title: "Your OTP is " + res.data.dev_otp,
                showConfirmButton: false,
                timer: 30000,
                timerProgressBar: true,
              });
            }
  
            this.countDown = 2 * 60;
            this.time = 2 * 60 * 1000;
  
            this.countDownTimer();
  
            this.otpSend = true;
            this.allowOtpReSend = false;
  
            // console.log("res 200", res);
          }
        } catch (error) {
          let data = {};
  
          if (error && error.response && error.response.data) {
            data = error.response.data;
          }
  
          const status =
            error && error.response && error.response.status
              ? error.response.status
              : 500;
  
          if (status == 401 || status == 422) {
            this.accountLocked = false;
  
            if ("idnumber" in data) {
              this.idnumberHasError = true;
              this.idnumberErrorMessage = data.idnumber[0];
            }
  
            if ("cellular" in data) {
              this.mobilePhoneHasError = true;
              this.mobilePhoneErrorMessage = data.cellular[0];
  
              // console.log(this.mobilePhoneErrorMessage);
            }
  
            // this.error = true;
            if ("result" in data) {
              if (data.result == "Account locked") {
                this.accountLocked = true;
              }
            }
  
            this.idnumberHasError = true;
            this.idnumberErrorMessage = "";
  
            this.mobilePhoneHasError = true;
            this.mobilePhoneErrorMessage = "";
  
            this.$refs.form.validate();
            this.setReadyToSubmit();
  
            // console.log("error.response", error.response);
            // console.log("data", data);
          } else {
            this.$helpers.handleError(status, data);
          }
        }
  
        this.overlay = false;
      },
  
      // resetPassword
      async resetPassword() {
        this.overlay = true;
  
        try {
          let res = await this.axios.post("reset-password", {
            idnumber: this.idnumber,
            password_reset_otp: this.otp,
            password: this.password,
            password_verify: this.password,
          });
  
          if (res.status == 200) {
            this.$swal({
              toast: true,
              position: "top-end",
              icon: "success",
              title: "Your Password Has Been Reset",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
  
            // console.log("res 200", res);
            this.setJustReseted(true);
  
            this.$router.push({ name: "LoginPage" });
          }
        } catch (error) {
          let data = {};
  
          if (error && error.response && error.response.data) {
            data = error.response.data;
          }
  
          const status =
            error && error.response && error.response.status
              ? error.response.status
              : 500;
  
          if (status == 401 || status == 422) {
            if ("idnumber" in data) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.idnumber[0],
              });
            }
  
            if ("id_number" in data) {
              this.$swal({
                icon: "error",
                title: "Oops...",
                text: data.id_number[0],
              });
            }
  
            if ("password_reset_otp" in data) {
              this.otpHasError = true;
              this.otpErrorMessage = data.password_reset_otp[0];
  
              // console.log(this.otpErrorMessage);
            }
  
            if ("password" in data) {
              this.passwordHasError = true;
              this.passwordErrorMessage = data.password[0];
            }
  
            if ("password_verify" in data) {
              this.passwordHasError = true;
              this.passwordErrorMessage = data.password_verify[0];
            }
            if (this.$refs.form2) {
              this.$refs.form2.validate();
            }
  
            // console.log("data", data);
          } else {
            this.$helpers.handleError(status, data);
          }
        }
  
        this.overlay = false;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  //
  </style>
  